.media-player-artwork-rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.playing-half-size-tile {
  animation: expand-tile 4s 1 forwards;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);  
}

@keyframes expand-tile {
  from {height: 70%;}
  to {height: 100%;}
}
