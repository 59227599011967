body {
  margin: 0;  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hover-text-underline:hover {
  text-decoration: underline;
}

.footer-link-hover-underline:hover {
  text-decoration: underline;
  color: #07f;
}

.SimpleDatePicker .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.SimpleDatePicker .DayPicker-Day {
  border-radius: 0 !important;
}

.SimpleDatePicker .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.SimpleDatePicker .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.uploadcare--widget__button,
.uploadcare--widget__dragndrop-area,
.uploadcare--progress,
.uploadcare--widget__text {
  display: none;
}

/* https://uicookies.com/css-gradient-button/ */
.count-down-upload-image-button {
  background: linear-gradient(to right, #895cf2 0%, #ffabf4 50%, #895cf2 100%);
  border-style: none;
  background-size: 400% 400%;
  animation: animate-gradient 15s ease infinite;
}

/* .count-down-upload-image-button:hover {
  background: linear-gradient(to right, #895cf2 0%, #ffabf4 50%, #895cf2 100%);
  border-style: none;
  background-size: 400% 400%;
  animation: animate-gradient 15s ease infinite;
} */

@keyframes animate-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.count-down-number:after {
  position: absolute;
  right: 0;
  content: ":";
  opacity: 0.3
}

/* https://codeburst.io/how-to-create-horizontal-scrolling-containers-d8069651e9c6 */


.horizontal-scroller {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  padding-top: 10px;
  padding-bottom: 10px;
}

.horizontal-scroller::-webkit-scrollbar {
  display: none;
}

.author-card {
  width: 140px;
  height: 140px;
  border-radius: 20px;
  padding: 10px;  
  color: black;
  /* margin-right: 10px; */
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, .7);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
}

.horizontal-scroller .author-card:not(:last-child) {
  margin-right: 10px;
}

/* Podcast People */

.podcast-people-wrapper {
  margin: 20px 0 20px 0;
}

.podcast-people-header {
  display: flex;
  justify-content: space-between;
}

.podcast-people-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  gap: 10px; 
}

.podcast-people-container::-webkit-scrollbar {
  display: none;
}

.people-nav-buttons {
  display: flex;
  gap: 20px;
}

.podcast-people-container > .podcast-person-card {
  width: 260px;
  height: 160px;
  border-radius: 8px;
  flex: 0 0 auto; /* This is essential */  
}

.podcast-people-container > .podcast-person-card > .podcast-person-wrapper {
  width: 100%;
  height: 100%;
  padding: 6px;
  display: flex;
  flex-direction: row;
  gap: 6px;
}


.person-avatar-container { 
  width: 90px;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* padding: 3px; */
}

.person-avatar {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.podcast-person-meta {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.podcast-person-meta > .person-basic-info > .person-name {
  overflow: hidden;
  font-size: 16px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;  
  -webkit-box-orient: vertical; 
}

.podcast-person-meta > .person-meta-roles {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.podcast-person-meta > .person-meta-roles > .person-meta-role {
  font-size: 10px;
  max-width: 80px;
  min-height: 30px;
  padding: 0 10px;
  /* width: 100%; */
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
